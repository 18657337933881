const apiConf = {
    developmentBaseUrl: "http://localhost:3000/api/v2/",
    developmentApiKey: "1",
    productionBaseUrl: "https://vastraapp.com/api/v2/",
    productionApiKey: "va$Tra@pP",

};

const placeHolderImages = {
    "DESIGN": "/images/placeholder/design_place_holder.svg",
    "CATEGORY": "/images/placeholder/category_place_holder.svg",
    "ORGANIZATION_LOGO": "/images/placeholder/category_place_holder.svg"
};


const changeColor = () => {
    const root = document.documentElement;
    // root?.style.setProperty("--lightblue", "yellow"); // Background Color
    // root?.style.setProperty("--title", "red"); // Main Button Color 
    // root?.style.setProperty("--blue", "orange"); // Secondary Button Color 
    // root?.style.setProperty("--red", "#5a9e6f"); // Red Font / Background Color 
    // root?.style.setProperty("--pink", "#dd0fff"); // Pink Font / Background Color 
    // root?.style.setProperty("--purple", "#0fdbff"); // Purple Font / Background Color 
    // root?.style.setProperty("--white", "grey"); // White Font / Background Color 
};

const navWithBackButton = [
    'bucket'
];

const privateRoutes = [
    'bucket',
];

module.exports = {
    placeHolderImages,
    changeColor,
    navWithBackButton,
    apiConf,
    privateRoutes
};